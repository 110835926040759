/**
 * This CSS file is being served from www.uniklinik-innsbruck.at, and is
 * only authorized for use from the following URLs:
 *
 * @allow http://www.uniklinik-innsbruck.at/
 *
 * This file includes the following fonts:
 * 
 * @name Freight Sans Pro
 * @vendorname Phil's Fonts
 * @vendorurl https://typekit.com/foundries/phils-fonts
 * 
 * These fonts are protected by copyright and licensing terms, and may not be
 * used without permission on other websites, servers, or computers. For full
 * terms, please see http://www.adobe.com/products/eulas/tou_typekit
 * 
 * (c) 2015 Innsbruck Medical University
 */


// Import dependendencies

/// import normalize-css to reset all browser inherent styling
@import "../vendor/bower/normalize-scss/normalize.scss";
/// breakpoint
@import "../vendor/bower/breakpoint-sass/stylesheets/breakpoint";
/// susy
@import "../vendor/bower/susy/sass/susy";
/// use border box sizing
@include border-box-sizing;



/// configure susy defaults
$susy: (
    global-box-sizing: border-box,
    use-custom: (
        rem: true
    ),
    //math: static,
    columns: 4,
    column-width: 283px,
    gutters: 6px/283px, // 6px gutter
    debug: (
    ///    image: show,
    ///    output: overlay,
    ///    toggle: top right,
    ),
);



/// declare variables
/// book = 400 | medium = 500 | semibold = 600 | bold = 700 | black = 900
$para-stack: 'freight-sans-pro', sans-serif;
$headline-stack:'freight-sans-pro', sans-serif;

/// the breakpoints
$narrow: 600px 850px;
$medium: 850px 1150px;
$wide: 1150px;



/// define local mixins




/// styles

html {
    /// base font-size of 62.5% allows using rem similar to px sizing
    /// 1.4rem ~ 14px; 2.4rem ~ 24px
    font-size: 62,5%;
}

body {
    font-size: 1rem;
    font-family: $para-stack;
    font-weight: 300;
    font-style: normal;
    color: #222;
    background-color: #fdfdfd;
    padding: 1rem;
}

/// The Susy grid

.container {
    @include container($susy);
}

.header {
    @include span(4 of 4 last);
    position: relative;
}

.title {
    
    @include span(4 of 4 last);
    bottom: 0;
    margin: 0 0 5px 0;
    
    @include breakpoint(narrow) {
        @include span(4 of 4 last);
        bottom: 0;
        margin: 0 0 5px 0;
    }
    
    @include breakpoint($medium) {
        @include span(3 of 4);
        position: absolute;
        bottom: 0;
        margin: 0 0 5px 0;
    }
    
    @include breakpoint($wide) {
        @include span(3 of 4);
        position: absolute;
        bottom: 0;
        margin: 0 0 5px 0;
    }
    
}

.logos {

    display:none;

    @include breakpoint($narrow) {
        display: none;
    }
    
    @include breakpoint($medium) {
        display: inline;
        @include span(1 of 4 last);
        text-align: right;
    }
    
    @include breakpoint($wide) {
        display: inline;
        @include span(1 of 4 last);
        text-align: right;
    }
    
}

.topmenu {

    display:none;
    
    @include breakpoint($medium) {
        display: block;
        @include span(4 of 4 last);
        margin: 0 0 30px 0;
        padding: 3px 6px 3px  6px;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
    }
    
    @include breakpoint($wide) {
        display: block;
        @include span(4 of 4 last);
        margin: 0 0 30px 0;
        padding: 3px 6px 3px  6px;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
    }
    
}

.images {
    @include span(4 of 4 last);
    margin: 6px 0 0 0;
}

.image1 {

    display: none;
    
    @include breakpoint($narrow) {
        @include span(1 of 4);
        display: inline;
    }
    
    @include breakpoint($medium) {
        @include span(1 of 4);
        display: inline;
    }
    
    @include breakpoint($wide) {
        @include span(1 of 4);
        display: inline;
    }
    
}

.image2 {

    @include span(4 of 4 last);
    
    @include breakpoint($narrow) {
        @include span(2 of 4);
    }
    
    @include breakpoint($medium) {
        @include span(2 of 4);
    }
    
    @include breakpoint($wide) {
        @include span(2 of 4);
    }
    
}

.image3 {

   display: none;
   
    @include breakpoint($narrow) {
        @include span(1 of 4 last);
        display: inline;
    }
    
    @include breakpoint($medium) {
        @include span(1 of 4 last);
        display: inline;
    }
    
    @include breakpoint($wide) {
        @include span(1 of 4 last);
        display: inline;
    }
    
}

.boxes {
    @include span(4 of 4 last);
    margin: 6px 0 0 0;
    overflow: hidden;
}

.box1 {

    padding: 0 10px 2010px 10px;
    margin-bottom: -2000px;
    background-color: #e1e1e1;

    @include breakpoint($narrow) {
        @include span(1 of 2);
    }
    
    @include breakpoint($medium) {
        @include span(1 of 2);
    }
    
    @include breakpoint($wide) {
        @include span(1 of 4);
    }
    
}

.box2 {
    
    padding: 0 10px 2010px 10px;
    margin-bottom: -2000px;
    border-top: 6px solid white;
    background-color: #e1e1e1;
    
    @include breakpoint($narrow) {
        @include span(1 of 2 last);
        border-top: none;
    }
    
    @include breakpoint($medium) {
        @include span(1 of 2 last);
        border-top: none;
    }
    
    @include breakpoint($wide) {
        @include span(1 of 4);
        border-top: none;
    }
    
}

.box3 {
    
    padding: 0 10px 2010px 10px;
    margin-bottom: -2000px;
    border-top: 6px solid white;
    background-color: #e1e1e1;
    
    @include breakpoint($narrow) {
        @include span(1 of 2);
        border-top: 6px solid white;
    }
    
    @include breakpoint($medium) {
        @include span(1 of 2);
        border-top: 6px solid white;
    }
    
    @include breakpoint($wide) {
        @include span(1 of 4);
        border-top: none;
    }
}

.box4 {
    
    padding: 0 10px 2010px 10px;
    margin-bottom: -2000px;
    border-top: 6px solid white;
    background-color: #e1e1e1;
    
    @include breakpoint($narrow) {
        @include span(1 of 2 last);
        border-top: 6px solid white
    }
    
    @include breakpoint($medium) {
        @include span(1 of 2 last);
        border-top: 6px solid white
    }
    
    @include breakpoint($wide) {
        @include span(1 of 4 last);
        border-top: none;
    }
}

.footer {
    @include span (4 of 4 last);
    margin-top: 30px;
    font-size: 0.9rem;
    text-align: center;
    color: #bbb;
}

/// box typography

.box1 > h3 {
    margin: 0 0 0 0;
    font-weight: 600;
    font-size: 2.0rem;
    color: #468;
}

.box1 > p {
    margin: 0 0 0 0;
}

.box2 > h3 {
    margin: 0 0 0 0;
    font-weight: 600;
    font-size: 2.0rem;
    color: #468;
}

.box2 > p {
    margin: 0 0 0 0;
}


.box3 > h3 {
    margin: 0 0 0 0;
    font-weight: 600;
    font-size: 2.0rem;
    color: #468;
}

.box3 > p {
    margin: 0 0 0 0;
}

.box4 > h3 {
    margin: 0 0 0 0;
    font-weight: 600;
    font-size: 2.0rem;
    color: #468;
}

.box4 > p {
    margin: 0 0 0 0;
}

/// anchors

a.box {
    color: #468;
    text-decoration: none;
}

a.box:hover {
    color: #468;
    text-decoration: underline;
}

.footer > a {
    color: #bbb;
    text-decoration: none;
}

.footer > a:hover {
    color: #bbb;
    text-decoration: underline;
}

.topmenu > a {
    margin-right: 20px;
    font-weight: 600;
    font-size: 0.9rem;
    text-decoration: none;
    color: #468;
}

.topmenu > a:hover {
    margin-right: 20px;
    font-weight: 600;
    font-size: 0.9rem;
    text-decoration: underline;
    color: #468;
}

/// images

img.emotion {
    display: block;
    max-width: 100%;
}

/// special classes

.claim {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    font-size: 1.8rem;
    font-family: $para-stack;
    font-weight: 500;
    color: #468;
}

.subtitle {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    font-size: 1.1rem;
    font-family: $para-stack;
}

/// standard headers

h1 {
    font-family: $headline-stack;
    font-weight: 400;
    font-size: 1.7rem;
    color: #468;
}

/// standard typo

.content {
    @include span(4 of 4 last);
    margin: 6px 0 0 0;
    padding: 0 10px 20px 10px;
    background-color: #e1e1e1;
    overflow: hidden;
    
    h3 {
        margin: 0 0 0 0;
        font-family: $headline-stack;
        font-weight: 600;
        font-size: 2.0rem;
        color: #468;
    }
    
    a {
    color: #468;
    text-decoration: none;
    }

    a:hover {
        color: #468;
        text-decoration: underline;
    }
    
}

