/**
 * This CSS file is being served from www.uniklinik-innsbruck.at, and is
 * only authorized for use from the following URLs:
 *
 * @allow http://www.uniklinik-innsbruck.at/
 *
 * This file includes the following fonts:
 * 
 * @name Freight Sans Pro
 * @vendorname Phil's Fonts
 * @vendorurl https://typekit.com/foundries/phils-fonts
 * 
 * These fonts are protected by copyright and licensing terms, and may not be
 * used without permission on other websites, servers, or computers. For full
 * terms, please see http://www.adobe.com/products/eulas/tou_typekit
 * 
 * (c) 2015 Innsbruck Medical University
 */
/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/**
 * Remove default margin.
 */
body {
  margin: 0;
}

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none;
}

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0;
}

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted;
}

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold;
}

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic;
}

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000;
}

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0;
}

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px;
}

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0;
}

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto;
}

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */
}

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal;
}

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */
}

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto;
}

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold;
}

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 62,5%;
}

body {
  font-size: 1rem;
  font-family: "freight-sans-pro", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #222;
  background-color: #fdfdfd;
  padding: 1rem;
}

.container {
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;
}

.container:after {
  content: " ";
  display: block;
  clear: both;
}

.header {
  width: 100%;
  float: right;
  margin-right: 0;
  position: relative;
}

.title {
  width: 100%;
  float: right;
  margin-right: 0;
  bottom: 0;
  margin: 0 0 5px 0;
}

@media (min-width: narrow) {
  .title {
    width: 100%;
    float: right;
    margin-right: 0;
    bottom: 0;
    margin: 0 0 5px 0;
  }
}

@media (min-width: 850px) and (max-width: 1150px) {
  .title {
    width: 74.86957%;
    float: left;
    margin-right: 0.52174%;
    position: absolute;
    bottom: 0;
    margin: 0 0 5px 0;
  }
}

@media (min-width: 1150px) {
  .title {
    width: 74.86957%;
    float: left;
    margin-right: 0.52174%;
    position: absolute;
    bottom: 0;
    margin: 0 0 5px 0;
  }
}

.logos {
  display: none;
}

@media (min-width: 600px) and (max-width: 850px) {
  .logos {
    display: none;
  }
}

@media (min-width: 850px) and (max-width: 1150px) {
  .logos {
    display: inline;
    width: 24.6087%;
    float: right;
    margin-right: 0;
    text-align: right;
  }
}

@media (min-width: 1150px) {
  .logos {
    display: inline;
    width: 24.6087%;
    float: right;
    margin-right: 0;
    text-align: right;
  }
}

.topmenu {
  display: none;
}

@media (min-width: 850px) and (max-width: 1150px) {
  .topmenu {
    display: block;
    width: 100%;
    float: right;
    margin-right: 0;
    margin: 0 0 30px 0;
    padding: 3px 6px 3px  6px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
}

@media (min-width: 1150px) {
  .topmenu {
    display: block;
    width: 100%;
    float: right;
    margin-right: 0;
    margin: 0 0 30px 0;
    padding: 3px 6px 3px  6px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
}

.images {
  width: 100%;
  float: right;
  margin-right: 0;
  margin: 6px 0 0 0;
}

.image1 {
  display: none;
}

@media (min-width: 600px) and (max-width: 850px) {
  .image1 {
    width: 24.6087%;
    float: left;
    margin-right: 0.52174%;
    display: inline;
  }
}

@media (min-width: 850px) and (max-width: 1150px) {
  .image1 {
    width: 24.6087%;
    float: left;
    margin-right: 0.52174%;
    display: inline;
  }
}

@media (min-width: 1150px) {
  .image1 {
    width: 24.6087%;
    float: left;
    margin-right: 0.52174%;
    display: inline;
  }
}

.image2 {
  width: 100%;
  float: right;
  margin-right: 0;
}

@media (min-width: 600px) and (max-width: 850px) {
  .image2 {
    width: 49.73913%;
    float: left;
    margin-right: 0.52174%;
  }
}

@media (min-width: 850px) and (max-width: 1150px) {
  .image2 {
    width: 49.73913%;
    float: left;
    margin-right: 0.52174%;
  }
}

@media (min-width: 1150px) {
  .image2 {
    width: 49.73913%;
    float: left;
    margin-right: 0.52174%;
  }
}

.image3 {
  display: none;
}

@media (min-width: 600px) and (max-width: 850px) {
  .image3 {
    width: 24.6087%;
    float: right;
    margin-right: 0;
    display: inline;
  }
}

@media (min-width: 850px) and (max-width: 1150px) {
  .image3 {
    width: 24.6087%;
    float: right;
    margin-right: 0;
    display: inline;
  }
}

@media (min-width: 1150px) {
  .image3 {
    width: 24.6087%;
    float: right;
    margin-right: 0;
    display: inline;
  }
}

.boxes {
  width: 100%;
  float: right;
  margin-right: 0;
  margin: 6px 0 0 0;
  overflow: hidden;
}

.box1 {
  padding: 0 10px 2010px 10px;
  margin-bottom: -2000px;
  background-color: #e1e1e1;
}

@media (min-width: 600px) and (max-width: 850px) {
  .box1 {
    width: 49.47552%;
    float: left;
    margin-right: 1.04895%;
  }
}

@media (min-width: 850px) and (max-width: 1150px) {
  .box1 {
    width: 49.47552%;
    float: left;
    margin-right: 1.04895%;
  }
}

@media (min-width: 1150px) {
  .box1 {
    width: 24.6087%;
    float: left;
    margin-right: 0.52174%;
  }
}

.box2 {
  padding: 0 10px 2010px 10px;
  margin-bottom: -2000px;
  border-top: 6px solid white;
  background-color: #e1e1e1;
}

@media (min-width: 600px) and (max-width: 850px) {
  .box2 {
    width: 49.47552%;
    float: right;
    margin-right: 0;
    border-top: none;
  }
}

@media (min-width: 850px) and (max-width: 1150px) {
  .box2 {
    width: 49.47552%;
    float: right;
    margin-right: 0;
    border-top: none;
  }
}

@media (min-width: 1150px) {
  .box2 {
    width: 24.6087%;
    float: left;
    margin-right: 0.52174%;
    border-top: none;
  }
}

.box3 {
  padding: 0 10px 2010px 10px;
  margin-bottom: -2000px;
  border-top: 6px solid white;
  background-color: #e1e1e1;
}

@media (min-width: 600px) and (max-width: 850px) {
  .box3 {
    width: 49.47552%;
    float: left;
    margin-right: 1.04895%;
    border-top: 6px solid white;
  }
}

@media (min-width: 850px) and (max-width: 1150px) {
  .box3 {
    width: 49.47552%;
    float: left;
    margin-right: 1.04895%;
    border-top: 6px solid white;
  }
}

@media (min-width: 1150px) {
  .box3 {
    width: 24.6087%;
    float: left;
    margin-right: 0.52174%;
    border-top: none;
  }
}

.box4 {
  padding: 0 10px 2010px 10px;
  margin-bottom: -2000px;
  border-top: 6px solid white;
  background-color: #e1e1e1;
}

@media (min-width: 600px) and (max-width: 850px) {
  .box4 {
    width: 49.47552%;
    float: right;
    margin-right: 0;
    border-top: 6px solid white;
  }
}

@media (min-width: 850px) and (max-width: 1150px) {
  .box4 {
    width: 49.47552%;
    float: right;
    margin-right: 0;
    border-top: 6px solid white;
  }
}

@media (min-width: 1150px) {
  .box4 {
    width: 24.6087%;
    float: right;
    margin-right: 0;
    border-top: none;
  }
}

.footer {
  width: 100%;
  float: right;
  margin-right: 0;
  margin-top: 30px;
  font-size: 0.9rem;
  text-align: center;
  color: #bbb;
}

.box1 > h3 {
  margin: 0 0 0 0;
  font-weight: 600;
  font-size: 2.0rem;
  color: #468;
}

.box1 > p {
  margin: 0 0 0 0;
}

.box2 > h3 {
  margin: 0 0 0 0;
  font-weight: 600;
  font-size: 2.0rem;
  color: #468;
}

.box2 > p {
  margin: 0 0 0 0;
}

.box3 > h3 {
  margin: 0 0 0 0;
  font-weight: 600;
  font-size: 2.0rem;
  color: #468;
}

.box3 > p {
  margin: 0 0 0 0;
}

.box4 > h3 {
  margin: 0 0 0 0;
  font-weight: 600;
  font-size: 2.0rem;
  color: #468;
}

.box4 > p {
  margin: 0 0 0 0;
}

a.box {
  color: #468;
  text-decoration: none;
}

a.box:hover {
  color: #468;
  text-decoration: underline;
}

.footer > a {
  color: #bbb;
  text-decoration: none;
}

.footer > a:hover {
  color: #bbb;
  text-decoration: underline;
}

.topmenu > a {
  margin-right: 20px;
  font-weight: 600;
  font-size: 0.9rem;
  text-decoration: none;
  color: #468;
}

.topmenu > a:hover {
  margin-right: 20px;
  font-weight: 600;
  font-size: 0.9rem;
  text-decoration: underline;
  color: #468;
}

img.emotion {
  display: block;
  max-width: 100%;
}

.claim {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  font-size: 1.8rem;
  font-family: "freight-sans-pro", sans-serif;
  font-weight: 500;
  color: #468;
}

.subtitle {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  font-size: 1.1rem;
  font-family: "freight-sans-pro", sans-serif;
}

h1 {
  font-family: "freight-sans-pro", sans-serif;
  font-weight: 400;
  font-size: 1.7rem;
  color: #468;
}

.content {
  width: 100%;
  float: right;
  margin-right: 0;
  margin: 6px 0 0 0;
  padding: 0 10px 20px 10px;
  background-color: #e1e1e1;
  overflow: hidden;
}

.content h3 {
  margin: 0 0 0 0;
  font-family: "freight-sans-pro", sans-serif;
  font-weight: 600;
  font-size: 2.0rem;
  color: #468;
}

.content a {
  color: #468;
  text-decoration: none;
}

.content a:hover {
  color: #468;
  text-decoration: underline;
}
